import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import mn_MN from 'antd/locale/mn_MN';
import LoadingScreen from './components/LoadingScreen';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingScreen />}>
      <ConfigProvider
        locale={mn_MN}
        theme={{
          token: {
            colorPrimary: '#1463F3',
          },
        }}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Suspense>
  </React.StrictMode>,
);
